import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BaseDialog } from '../../Dialog-types/base-dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { ButtonInfo } from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import { BackgroundOperationsApiSvc } from 'src/app/services/api.services/background.operations.api.svc';
import { DownloadFileHelper } from 'src/app/services/download.file.helper';
import { Subscription } from 'rxjs';

enum OperationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED"
}

interface IOperations {
  name: string;
  siteName: string;
  status: string;
  details: string;
  startTime: string;
  endTime: string;
  downloadUrl: string;
  siteDeleted: boolean;
}


@Component({
  selector: 'ins-operation-status-board-dialog',
  templateUrl: './operation-status-board-dialog.component.html',
  styleUrls: ['./operation-status-board-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})


export class OperationStatusBoardDialogComponent extends BaseDialog implements OnInit {
  public displayedProjectColumns: string[] = ['name', 'status', 'details', 'siteName', 'startTime', 'endTime', 'downloadUrl'];
  public tableDataSource: MatTableDataSource<any>;
  public noData;
  public showLoading: boolean = false;
  public pageRefreshInprogress: boolean = false;
  public statusSortDisabled: boolean = false;

  public operationsData: IOperations[] = [];
  public OperationStatus = OperationStatus;
  public selectedStatusFilter: string = "";

  public pageSize = 10;
  public pageIndex = 1;
  public totalRecords = 0;
  public orderBy: string;
  public order: string;
  public pollingTimerId: any;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public operationStatusTextMap: Map<OperationStatus, string> = new Map()
  .set(OperationStatus.FAILED, 'Failed')
  .set(OperationStatus.COMPLETED, 'Completed')
  .set(OperationStatus.IN_PROGRESS, 'In progress');


  public buttonsInfo: ButtonInfo[] = [new ButtonInfo('close', 'Close')];
  public statusFilterOptions: ButtonInfo[] = [
    new ButtonInfo("", 'All'),
    new ButtonInfo(OperationStatus.FAILED, this.operationStatusTextMap.get(OperationStatus.FAILED)),
    new ButtonInfo(OperationStatus.COMPLETED, this.operationStatusTextMap.get(OperationStatus.COMPLETED)),
    new ButtonInfo(OperationStatus.IN_PROGRESS, this.operationStatusTextMap.get(OperationStatus.IN_PROGRESS))
  ];

  private currentRequestSubscription: Subscription | null = null;
  
  constructor(public injector: Injector, private backgroundOperationsApiSvc: BackgroundOperationsApiSvc) {
    super(injector);
  }

  onChanges(): void {}

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource(this.operationsData);
    this.tableDataSource.sort = this.sort;
    this.sort.sortChange.subscribe((sortState: Sort) => {
      if (sortState) {
        this.orderBy = sortState.active;
        this.order = sortState.direction;
        this.pageIndex = 1;
        this.fetchBackgroundOperations();
      }
    });
    this.showLoading = true;
    this.fetchBackgroundOperations();
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel([]);
  }

  statusFilterChanged(status: string): void {
    if (status === "") {
      this.statusSortDisabled = false;
    } else {
      if (this.sort.active === 'status') {
        this.sort.sort({ id: '', start: 'asc', disableClear: false });
      }
      this.statusSortDisabled = true
    }
    this.selectedStatusFilter = status;
    this.pageIndex = 1;
    this.fetchBackgroundOperations();
  }

  getToolTipConf(element: any, label: string): any {
    let toolTipConf = {text: '', position: { H: 'hLeft', V:'vBottom' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }

  getStatusClass(status: any): string {
    let statusText = 'success';
    switch (status) {
      case OperationStatus.IN_PROGRESS:
        statusText = 'inprogress';
        break;
      case OperationStatus.COMPLETED:
        statusText = 'success';
        break;
      case OperationStatus.FAILED:
        statusText = 'error';
        break;
    }
    return statusText;
  }

  pageChangeEvent(event: any) {   
    this.pageIndex = event;
    this.fetchBackgroundOperations();
  }

  downloadReport(downloadUrl: string): void {
    DownloadFileHelper.downloadFile(downloadUrl, "");
  }

  refresh(): void {
    this.pageRefreshInprogress = true;
    this.fetchBackgroundOperations();
  }

  fetchBackgroundOperations(): void {
    this.currentRequestSubscription && this.currentRequestSubscription.unsubscribe();
    this.currentRequestSubscription = this.backgroundOperationsApiSvc.getBackgroundOperationswithPage(
      this.selectedStatusFilter, this.orderBy, this.order, this.pageIndex, this.pageSize
    ).subscribe((data: any) => {
      this.totalRecords = data.totalRecords;
      this.pageRefreshInprogress = false;
      this.showLoading = false;
      this.operationsData = data.data;
      this.tableDataSource = new MatTableDataSource(this.operationsData);
      this.noData = this.tableDataSource.connect().pipe(map(obj => obj.length === 0));
      this.startPolling();
    });
  }

  startPolling(): void {
    this.clearExistingTimer();
    this.pollingTimerId = setTimeout(() => {
      this.fetchBackgroundOperations();
    }, 30000);
  }

  clearExistingTimer() {
    if (this.pollingTimerId) {
      clearInterval(this.pollingTimerId);
      this.pollingTimerId = null;
    }
  }

  ngOnDestroy() {
    this.clearExistingTimer();
    this.currentRequestSubscription && this.currentRequestSubscription.unsubscribe();
  }
}